import cookies from 'cookies-js'
import store from 'store2'
import uuid from 'uuid'

import { apiRequest } from './auth'
import mondoApi from './mondoSdk'

const getSessionID = () => {
  let sessionID = getItem('sessionId')

  if (sessionID) return sessionID

  sessionID = uuid.v4()
  setItem('sessionId', sessionID)
  return sessionID
}

export const trackEvent = async (
  events: {
    name: string
    data: Record<string, any>
  }[],
) => {
  const sessionID = getSessionID()
  await apiRequest(
    mondoApi.trackAnalyticsEvent,
    {
      events: [
        ...events.map(({ name, data }) => ({
          name,
          data: augmentData(data),
        })),
        {
          name: 'pageview',
          data: {
            url: window.location.href,
          },
        },
      ],
    },
    {
      'Mondo-Session': sessionID,
    },
  )
}

function augmentData(data = {}) {
  return {
    ...data,
    referrer: document.referrer,
    user_languages: navigator.languages || [navigator.language], // @TODO: This isn't very reliable
  }
}

function setItem(k: string, v: string) {
  const storage = store.local
  // write to both local storage and cookies so we can track sessions
  // between iOS Safari and SFSafariViewController (which share cookies
  // but not local storage)
  storage.set(`mondoAnalytics:${k}`, v)
  cookies.set(`mondoAnalytics:${k}`, v, { expires: Infinity, secure: true })
}

function getItem(k: string) {
  const storage = store.local
  let result

  result = storage.get(`mondoAnalytics:${k}`)
  if (result != null) {
    // found key in local storage, copy to cookie
    cookies.set(`mondoAnalytics:${k}`, result, {
      expires: Infinity,
      secure: true,
    })
  } else {
    result = cookies.get(`mondoAnalytics:${k}`)
    if (result != null) {
      // found key in cookie, copy to local storage
      storage.set(`mondoAnalytics:${k}`, result)
    }
  }

  return result || ''
}
