import c from 'classnames'
import React from 'react'

import type { Payment } from '../../types/payment'
import { formatAmount } from '../../utils/payment'
import Avatar from '../Avatar'
import styles from './SmallPaymentDescription.module.scss'

type Props = {
  payment: Payment
}

const SmallPaymentDescription = ({ payment }: Props) => {
  const {
    sender_user_id: userID,
    sender_full_name: userFullName,
    notes,
  } = payment
  const amount = formatAmount(payment)
  return (
    <section className={styles['profile']} aria-label="Payment description">
      <div className={styles['avatar']}>
        <Avatar small userId={userID} name={userFullName} />
      </div>
      <div className={c(styles['name-desc'], !notes ? styles['no-desc'] : '')}>
        <div>
          <span className={styles['profile-name']}>{userFullName}</span>
        </div>
        {notes ? (
          <div className={styles['description']}>
            <span aria-label="Payment message">{notes}</span>
          </div>
        ) : null}
      </div>
      <div className={styles['amount-wrapper']}>
        <span
          className={styles.amount}
          style={{
            fontSize: amount.length > 5 ? '20px' : '24px',
          }}
        >
          <span className={styles.currency}>£</span>
          {amount}
        </span>
      </div>
    </section>
  )
}

export default SmallPaymentDescription
