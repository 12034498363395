import React from 'react'

class RedirectToMonzoCom extends React.Component {
  override componentDidMount() {
    window.location.replace('https://monzo.com')
  }

  override render() {
    return null
  }
}

export default RedirectToMonzoCom
