import cx from 'classnames'
import Link from 'next/link'
import * as React from 'react'

import styles from './LinkAsButton.module.scss'

type Props = {
  buttonStyle?: string
  disabled?: boolean
  loading?: boolean
  className?: string
  to: string
  children?: React.ReactNode | null | undefined
}

export const LinkAsButton = (props: Props) => {
  let classNames = [styles['default']]
  if (props.buttonStyle === 'primary') {
    classNames = [styles['primary']]
  } else if (props.buttonStyle === 'primary-small') {
    classNames = [styles['primary-small']]
  }
  if (props.disabled) {
    classNames.push(styles['disabled'])
  }
  if (props.loading) {
    classNames.push(styles['loading'])
  }

  return (
    <Link href={props.to}>
      <a className={cx(classNames, props.className)}>{props.children}</a>
    </Link>
  )
}

export default LinkAsButton
