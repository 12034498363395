import Link from 'next/link'
import React from 'react'

import styles from './Claim/Claim.module.scss'

type Props = {
  email?: string
  status: string
  code: string | null
  claimType: string
}

export default function PaymentCompleted({
  email,
  claimType,
  code,
  status,
}: Props) {
  if (status === 'claimed' && claimType === 'email') {
    // This will be the case once the page is refreshed after an e-mail claim.
    // In this case we can allow the user to change their mind.
    return (
      <div className={styles['claim-description']} aria-live="assertive">
        <p>You've already claimed this money.</p>
        <p>
          You'll get it as soon as you finish signing up to Monzo with the same
          email address.
        </p>
        <Link href={`/claim/another-account#${code}`}>
          Send to another account instead
        </Link>
      </div>
    )
  }

  if (status === 'claimed' || status === 'paid') {
    return (
      <div className={styles['claim-description']} aria-live="assertive">
        You've already claimed this payment.
      </div>
    )
  }

  if (status === 'claimed_by_fps') {
    return (
      <div className={styles['claim-description']} aria-live="assertive">
        <h2 className={styles.success}>Success!</h2>
        <p>Your payment should arrive within 2 hours (usually instantly).</p>
      </div>
    )
  }

  if (status === 'failed') {
    return (
      <div className={styles['claim-description']}>
        Sorry, something went wrong. Please contact the sender.
      </div>
    )
  }

  if (status === 'expired') {
    return (
      <div className={styles['claim-description']} aria-live="assertive">
        This payment has expired.
      </div>
    )
  }

  if (status === 'claimed_by_fps_loading') {
    return (
      <div className={styles['claim-description']} aria-live="assertive">
        Sending...
      </div>
    )
  }

  if (status === 'claimed_by_email') {
    return (
      <>
        <div className={styles['claim-description']} aria-live="assertive">
          <p>Nearly there! Download Monzo and sign up to get your money.</p>
          <p>
            {email != null && email !== ''
              ? ` Make sure you use the same email: ${email}`
              : ' Make sure you use the same email you provided.'}
          </p>
        </div>
        <div className={styles['app-store-container']}>
          <a
            href="https://itunes.apple.com/gb/app/monzo/id1052238659?mt=8"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/app-store.png" alt="Monzo App" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=co.uk.getmondo&hl=en_GB&utm_source=payanyone&utm_medium=website"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/play-store.png" alt="Monzo App" />
          </a>
        </div>
      </>
    )
  }

  if (status === 'cancelled') {
    return (
      <div className={styles['claim-description']} aria-live="assertive">
        The sender has cancelled this payment.
      </div>
    )
  }

  return null
}
