import React from 'react'

import styles from './SignupBonusBanner.module.scss'

interface SignupBonusBannerProps {
  amount: number
}

const SignupBonusBanner: React.FC<SignupBonusBannerProps> = ({ amount }) => {
  if (amount > 0) {
    return (
      <div className={styles['signup-bonus-banner']}>
        <p>Get an extra £{amount} when you sign up to Monzo</p>
      </div>
    )
  }

  return null
}

export default SignupBonusBanner
