import React from 'react'

import styles from './Amount.module.scss'

type Props = {
  amount: string
  symbol?: string
}

export default function Amount({ amount, symbol = '£' }: Props) {
  return (
    <div
      className={styles[amount.length > 5 ? 'amount-large' : 'amount-larger']}
    >
      <span className={styles['currency-symbol']}>{symbol}</span>
      {amount}
    </div>
  )
}
