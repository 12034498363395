import getConfig from 'next/config'
import * as React from 'react'

import styles from './Avatar.module.scss'

type Props = {
  userId: string
  name: string
  tick?: boolean
  small?: boolean
}

const { publicRuntimeConfig: env } = getConfig()

const getInitials = (name: string) => {
  const parts = name.split(' ')

  let initials = ''
  for (let i = 0; i < parts.length; i++) {
    initials += parts[i]?.substring(0, 1).toUpperCase()
  }
  if (initials.length > 3) {
    initials = initials[0] || ''
  }

  return initials
}

const getStyle = (name?: string) => {
  if (!name) {
    return '0'
  }

  const sum = [...name]
    .map((letter) => letter.charCodeAt(0))
    .reduce((current, previous) => previous + current)
  return (sum % 8).toString()
}

const Avatar = (props: Props) => {
  const [showImage, setShowImage] = React.useState(false)

  const { userId, small, name } = props
  const initials = getInitials(name)

  return (
    <div className={styles[small ? 'avatar-small' : 'avatar']}>
      <img
        className={styles[showImage ? 'image' : 'hidden']}
        src={`${env.apiBaseURL}/user-images/profile_picture/${userId}?w=140&h=140`}
        alt=""
        onLoad={() => setShowImage(true)}
        onError={() => setShowImage(false)}
      />

      <div
        className={styles[showImage ? 'hidden' : `initials-${getStyle(name)}`]}
      >
        {initials}
      </div>
      {props.tick && (
        <img className={styles.tick} src={require('./tick.svg')} alt="Tick" />
      )}
    </div>
  )
}

export default Avatar
