import React from 'react'

import { useAppContext } from '../AppContext'
import Claim from '../components/Claim/Claim'
import { PaymentDescription } from '../components/PaymentDescription'
import RedirectToMonzoCom from '../components/RedirectToMonzoCom'
import usePageTracking from '../enhancers/usePageTracking'
import NotFound from '../pages/404'

export const AmountPage = () => {
  const { code, payment, status, errors } = useAppContext()
  usePageTracking({ event: 'pay-anyone.web-page.show', payment })
  const { notFound } = errors || {}
  if (code == null) {
    return <RedirectToMonzoCom />
  }

  if (notFound) {
    return <NotFound />
  }
  if (payment == null) {
    return null
  }

  return (
    <>
      <PaymentDescription payment={payment} />
      <Claim payment={payment} code={code} status={status} />
    </>
  )
}

export default AmountPage
