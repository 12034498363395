import { useEffect } from 'react'

import type { Payment } from '../types/payment'
import { trackEvent } from '../utils/analytics'

const usePageTracking = ({
  event,
  payment,
}: {
  event: string
  payment: Payment | undefined
}) => {
  useEffect(() => {
    const track = (payment: Payment) => {
      trackEvent([
        {
          name: event,
          data: {
            id: payment.id,
          },
        },
      ])
    }

    if (payment) {
      track(payment)
    }
  }, [event, payment])
}

export default usePageTracking
