import React from 'react'

import Amount from '../components/Amount'
import Avatar from '../components/Avatar'
import styles from '../components/PaymentDescription/PaymentDescription.module.scss'

const NotFoundPage = () => {
  return (
    <div className={styles.profile}>
      <Avatar userId={'not_found'} name={'?'} />
      <div className={styles.amount}>
        <Amount amount={'404'} symbol={''} />
      </div>
      <div className={styles.description}>Payment not found</div>
    </div>
  )
}

export default NotFoundPage
