import React from 'react'

import type { Payment } from '../../types/payment'
import { formatAmount } from '../../utils/payment'
import Amount from '../Amount'
import Avatar from '../Avatar'
import styles from './PaymentDescription.module.scss'

type Props = {
  payment: Payment
}

const PaymentDescription = ({ payment }: Props) => {
  const {
    sender_user_id: userID,
    sender_full_name: userFullName,
    notes,
  } = payment
  return (
    <>
      <div className={styles['profile']}>
        <Avatar userId={userID} name={userFullName} />
        <p>
          <span className={styles['profile-name']}>{userFullName}</span> wants
          to send you
        </p>
        <div className={styles['amount']}>
          <Amount amount={formatAmount(payment)} />
        </div>
      </div>

      <p className={styles['description']}>
        <span aria-label="Payment message">{notes}</span>
      </p>
    </>
  )
}

export default PaymentDescription
